<template>
  <div>
    <div id="film" data-section>
      <a name="film"></a>
      <stage />
    </div>
    <div id="das-besser-gefuehl" data-section>
      <a name="das-besser-gefuehl"></a>
      <story />
    </div>
    <div id="emotionserkennung" data-section>
      <a name="emotionserkennung"></a>
      <symphony />
    </div>
    <div id="gewinnspiel" data-section>
      <a name="gewinnspiel"></a>
      <teaser />
    </div>
    <div id="aspirin-produkte" data-section>
      <a name="aspirin-produkte"></a>
      <products />
    </div>
  </div>
</template>

<script>
/* eslint-disable */
import Stage from '@/modules/Stage'
const Story = () => import('@/modules/Story' /* webpackChunkName: "story" */)
const Symphony = () => import('@/modules/Symphony' /* webpackChunkName: "symphony" */)
const Teaser = () => import('@/modules/Teaser' /* webpackChunkName: "teaser" */)
const Products = () => import('@/modules/Products' /* webpackChunkName: "products" */)
/* eslint-enable */

export default {
  components: {
    Stage,
    Story,
    Symphony,
    Products,
    Teaser
  },
  methods: {
    onSectionChange(changes) {
      let highestRatio = 0
      let mostChanged = null
      for (let i = 0; i < changes.length; i++) {
        let change = changes[i]
        if (
          change.intersectionRatio >= 0.5 &&
          highestRatio < change.intersectionRatio
        ) {
          highestRatio = change.intersectionRatio
          mostChanged = change
        }
      }
      if (mostChanged) {
        this.$store.commit('setActiveSection', mostChanged.target.id)
      }
    }
  },
  created() {
    this.observer = new IntersectionObserver(this.onSectionChange, {
      root: null,
      threshold: 0.5
    })
  },
  mounted() {
    const sections = this.$el.querySelectorAll('[data-section]')
    for (let i = 0; i < sections.length; i++) {
      this.observer.observe(sections[i])
    }
  }
}
</script>
