<template>
  <section class="stage u-center" :class="{ 'is-playing': isPlaying }">
    <button v-if="isAndroid && !showPoster" @click="pauseVideo" class="stage__pause-btn stage__pause-fallback">
      <PauseButton class="pause" />
    </button>
    <div class="stage__content" :class="{ 'hidden': !showPoster}" >
      <div class="stage__wrapper o-panel__inner">
        <div class="stage__copy">
          <div
            class="stage__play-btn"
            @click="playVideo"
            :class="{ 'is-playing': isPlaying }"
          >
            <PlayButton />
            <span>Play</span>
          </div>
          <h1 class="u-h1">Wie klingt eigentlich „Besser“?</h1>
          <h2 class="u-h2">
            Erlebe, wie ein Gefühl zu Klang wird - und entdecke mit der
            Emotionserkennung von Aspirin® den Soundtrack deiner eigenen
            Emotionen.
          </h2>
        </div>
        <div class="stage__controls">
          <button @click="toggleVideoPlayback" class="stage__pause-btn">
            <PauseButton class="pause" v-if="isPlaying" />
            <PlayButton class="play" v-if="!isPlaying" />
          </button>
          <button @click="toggleMute" class="stage__sound-btn">
            <SoundOnButton class="mute" v-if="isMuted" />
            <SoundOffButton class="unmute" v-if="!isMuted" />
          </button>
        </div>
        <div class="stage__scroll-hint scroll-hint">
          <div class="scroll-hint__letters">
            <span>S</span>
            <span>c</span>
            <span>r</span>
            <span>o</span>
            <span>l</span>
            <span>l</span>
          </div>
          <div class="scroll-hint__line"></div>
        </div>
      </div>
      <div class="stage__posterimage" ref="poster">
        <img
          src="@/assets/images/intro-poster.jpg"
          style="height:0"
          ref="posterImage"
        />
      </div>
    </div>
    <div class="stage__bg">
      <youtube
        class="stage__video"
        @playing="onVideoStart"
        @paused="onVideoPaused"
        @ended="onVideoEnd"
        :width="1920"
        :height="1080"
        :resize="false"
        :video-id="videoId"
        :player-vars="playerVars"
        :nocookie="true"
        loop="true"
        ref="youtube"
      >
      </youtube>
    </div>
  </section>
</template>

<script>
import Vue from 'vue'
import VueYoutube from 'vue-youtube'
import PlayButton from '@/assets/images/new/play-brand.svg?inline'
import SoundOffButton from '@/assets/images/new/volume.svg?inline'
import SoundOnButton from '@/assets/images/new/volume-off.svg?inline'
import PauseButton from '@/assets/images/new/pause.svg?inline'
import imagesLoaded from 'imagesloaded'
import scrollMonitor from 'scrollmonitor'
import MobileDetect from 'mobile-detect'

Vue.use(VueYoutube)

export default {
  components: {
    PlayButton,
    PauseButton,
    SoundOnButton,
    SoundOffButton
  },
  data() {
    return {
      isPlaying: null,
      isMuted: false,
      videoId: '1I0o_CI2Jos',
      showPoster: true,
      isAndroid: false
    }
  },
  methods: {
    playVideo() {
      this.player.playVideo()
    },
    pauseVideo() {
      this.player.pauseVideo()
    },
    onVideoStart() {
      this.isPlaying = true
      if (this.isPortrait) {
        this.isPlaying = false
      }
      if(this.isAndroid) {
        this.showPoster = false
      }
    },
    onVideoPaused() {
      this.isPlaying = false
      if(this.isAndroid) {
        this.showPoster = true
      }
    },
    onVideoEnd() {
      this.isPlaying = false
      this.player.playVideo()
    },
    toggleVideoPlayback() {
      if (this.isPlaying) {
        setTimeout(() => {
          this.player.pauseVideo()
          if(this.isAndroid) {
            this.showPoster = true
          }
        }, 300)
      } else {
        this.player.playVideo()
        if(this.isAndroid) {
          this.showPoster = false
        }
      }
    },
    toggleMute() {
      this.isMuted = !this.isMuted
      if (this.isMuted) {
        this.player.mute()
      } else {
        this.player.unMute()
      }
    }
  },
  computed: {
    isPortrait() {
      return window.matchMedia('(max-device-width: 700px) and (orientation: portrait)').matches
    },
    player() {
      return this.$refs.youtube.player
    },
    playerVars() {
      return {
        autoplay: 1,
        modestbranding: 1,
        controls: 0,
        fs: 0,
        hl: 'de',
        iv_load_policy: 3,
        loop: 1,
        rel: 0,
        showinfo: 0,
        ecver: 2,
        disablekb: 1,
        autohide: 1,
        playsinline: this.isPortrait ? 0 : 1,
        origin: `${window.location.protocol}://${window.location.hostname}`
      }
    }
  },
  mounted() {
    const device = new MobileDetect(window.navigator.userAgent)
    const watcher = scrollMonitor.create( document.querySelector('.stage') )
    this.isAndroid = device.os() === 'AndroidOS'

    watcher.exitViewport(() => {
      this.player.pauseVideo()
    })

    imagesLoaded(this.$refs.poster, () => {
      const imageSrc = this.$refs.posterImage.src
      this.$refs.poster.style.backgroundImage = `url('${imageSrc}')`
      this.$store.commit('setIsSiteLoading', false)
    })
  }
}
</script>

<style lang="stylus">
@require '../assets/style/1-settings/*'
@require '../assets/style/2-tools/responsive'

.stage
  background $colorSiteBackground
  position relative
  overflow hidden
  height 100vh
  height calc(var(--vh, 1vh) * 100)

  &__pause-fallback
    position absolute
    left 50%
    top 50%
    transform translate(-50%,-50%)
    z-index 99

  .hidden
    opacity 0

  &__posterimage
    height 100vh
    height calc(var(--vh, 1vh) * 100)
    background-repeat no-repeat
    background-position center
    background-size cover
    opacity 1
    transition opacity .3s ease

    .is-playing &
      opacity 0
      transition-delay 0.5s

    +mobile()
      background-image url('~@/assets/images/intro-poster-mobile.jpg')

  &__bg
    width 100%
    height 100%
    overflow hidden
    position absolute
    top 0
    left 0
    bottom 0
    right 0

  &__video
    position absolute
    width 177.77777778vh
    height 56.25vw
    top 50%
    left 50%
    transform translate(-50%, -50%)
    min-height 100%
    min-width 100%

  &__scroll-hint
    align-self flex-end

    +mobile()
      right -5%

  &__content
    position absolute
    top 0
    left 0
    width 100%
    height inherit
    z-index 1

  &__wrapper
    width 100%
    position absolute !important
    bottom 0
    left 50%
    transform translate(-50%, 0)
    z-index 3
    display flex
    justify-content space-between

  &__copy
    text-align left
    margin-bottom 4rem
    max-width 20rem
    margin-right auto

    h1, h2
      color white

    h1
      font-family $fontRegular

    h2
      line-height 1.7
      margin-bottom 0

    +mobile()
      margin-bottom 7rem
      margin-left 1.5rem

      h1
        line-height 1.4
        font-size 30px
        max-width 250px

      h2
        max-width 270px

  &__play-btn
    display none
    margin-left -12px
    margin-bottom 1.6rem
    align-items center

    > svg
      width 55px
      margin-right 0.75rem

    +mobile()
      display flex

  &__controls
    position absolute
    left 50%
    transform translate(-50%, 0)
    bottom 4rem
    display flex

    +mobile()
      display none

  &__sound-btn,
  &__pause-btn
    cursor pointer
    background none
    border none
    width 32px
    height 32px
    display flex
    padding 0
    margin 0
    justify-content center
    align-items center

    > svg path
      fill white

  &__sound-btn
    margin-left 1.75rem

  &__sound-btn > svg
    width 20px
    height 32px

  &__pause-btn
    > svg.pause
      width 18px
      height 32px

    > svg.play
      width 32px
      height 32px

.scroll-hint
  position relative
  width 5rem
  margin-left auto
  left 3rem

  +mobile()
    position absolute
    left 5rem

  &__letters
    transform rotate(90deg)
    transform-origin 33% -110%
    overflow hidden
    white-space nowrap
    position absolute
    left 1.5rem
    top 0.75rem

    span
      margin 0
      line-height 1
      color white
      float left
      animation scrollhint 4s cubic-bezier(.77,0,.175,1) infinite
      font-size 13px

      &:nth-child(2)
        animation-delay 20ms
      &:nth-child(3)
        animation-delay 40ms
      &:nth-child(4)
        animation-delay 60ms
      &:nth-child(5)
        animation-delay 80ms
      &:nth-child(6)
        animation-delay .1s

  &__line
    width 2px
    background $colorBrand
    height 100px

    +mobile()
      height 80px

@keyframes scrollhint
  0%
    transform translateZ(0)
  60%
    transform translateZ(0)
  80%
    transform translate3d(0,100%,0)
  80.01%
    transform translate3d(0,-100%,0)
  to
    transform translateZ(0)
</style>
